import { navigate, RouteComponentProps } from "@reach/router";
import React, { useContext, useEffect, useState } from "react";
import {APIError, apiPatch, apiPost} from "../api/api-calls";
import { LoginContext } from "../context/login-context/login-context";
import { WizardContext } from "../context/wizard-context";
import { useCampaign, useCampaignMutators } from "../hooks/use-campaign";
import { usePostEventValidation } from "../hooks/use-post-event-validation";
import { useTargetingFromCampaign, useTargetingResolutionFromCampaign } from "../hooks/use-targeting";
import { useCampaignTemplateInformation } from "../hooks/use-template-information";
import { useTemplate } from "../hooks/use-templates";
import { useTranslation } from "../hooks/use-translation";
import { Customize } from "../pages/customize";
import { Send } from "../pages/send";
import { TargetingEditor } from "../pages/targeting-editor";
import { TemplateSelector } from "../pages/templates";
import {Substitutions, SegmentStore} from "../types";
import { NewCampaignSpecs } from "../types/campaigns";
import { Steps } from "./steps";
import { WizardStep } from "./wizard-wrapper";
import * as SmsCounter from "sms-counter-npm";
import { emptySmsCounterData, SmsContext } from "../context/sms-context";
import { generateCustomizationsObject } from "../helpers/generate-customizations-object";
import { replaceSmsVariablesWithSubstitutions } from "../helpers/replace-sms-variables-with-substitutions";
import {useClientContext} from "../hooks/use-client-context";
import {useTemplateFormConfiguration} from "../hooks/use-template-form-config";
import {useTemplateFormConfigurationForSMS} from "../hooks/use-template-form-config-for-sms";
import {useNotification} from "../hooks/use-notification";
import {Loader} from "./loader";

type Props = {
  campaignId?: string;
  currentStep: WizardStep;
};

type NewSubstitutionModel = {
  templateId?: string;
  customizations: { field: string; value: string }[] | [{}];
};
type NewSubstitutionModelWithOpenAi = {
  openAiToken?: string;
  licenseCode?: string;
  templateId?: string;
  customizations: { field: string; value: string }[] | [{}];
};

export const Wizard: React.FC<RouteComponentProps & Props> = ({ campaignId, currentStep }, props) => {
  const { activeClub: scope, user } = useContext(LoginContext);
  const { channel } = useContext(WizardContext);
  const [smsCounterData, setSmsCounterData] = useState<SmsCounter.ICounterResult>(emptySmsCounterData);
  const [smsText, setSmsText] = useState<string>("");

  const { templateId, customizations, refetch: refreshCampaignTemplateInformation } = useCampaignTemplateInformation(
    campaignId, channel);

  const {clientContext} =useClientContext();
  const isOpenAiActivated = clientContext?.openAiServiceIsEnabled;
  const licenseCodeInput = clientContext?.licenseCode;
  const openAiTokenInput = clientContext?.openAiToken;
  const { fetchedCampaignName, status , subscription} = useCampaign(campaignId, channel);
  const { template } = useTemplate(templateId,campaignId);

  const { substitutions: defaultTemplateSubstitutionsForEMAIL, variableNames:variableNamesForEMAIL } = useTemplateFormConfiguration(campaignId, templateId, channel,clientContext?.enabledTemplateWithActito);
  const { substitutions: defaultTemplateSubstitutionsForSMS, variableNames:variableNamesForSMS } = useTemplateFormConfigurationForSMS(templateId,channel);
  const defaultTemplateSubstitutions = channel === "EMAIL"? defaultTemplateSubstitutionsForEMAIL:defaultTemplateSubstitutionsForSMS;
  const variableNames = channel === "EMAIL"? variableNamesForEMAIL : variableNamesForSMS;
  const { translate } = useTranslation();
  const { updateStatus } = useCampaignMutators();
  const { validatePostEvent } = usePostEventValidation(campaignId);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [isSelectionDisabled, setIsSelectionDisabled] = useState<boolean>(templateId !== undefined);
  // Templates related

  const [campaignName, setCampaignName] = useState<string>(fetchedCampaignName);

  // customize related
  const [substitutions, setSubstitutions] = useState<Substitutions>({});

  const handleNewSubstitions = async (substitutionUpdates: Substitutions) => {
    if (!template) {
      throw new Error("cannot update substitutions for unknown template");
    }
    let newSubstitutions;
    if(enabledTemplateWithActito) {
      newSubstitutions = { ...substitutionUpdates };
    } else {
      const { replyTo, subject } = template;
      newSubstitutions = { subject, replyTo, ...substitutionUpdates };
    }
    setSubstitutions(newSubstitutions);
    const newSubstitutionModel: NewSubstitutionModel = { templateId: templateId, customizations: [] };
    for (const [field, value] of Object.entries(newSubstitutions)) {
      newSubstitutionModel.customizations.push({ field: `${field}`, value: `${value}` });
    }
    await apiPatch(`/api/campaigns/${campaignId}/template?channel=${channel}`, newSubstitutionModel);
    if (!campaignId) {
      throw new Error("Cannot update status of unknown campaign");
    }
    await updateStatus({
      campaignId,
      user: user || "",
      status: "DRAFT",
      shouldPostEvent: validatePostEvent("customize.event"),
      comment: `${translate("customize.event")}`,
      enableWithActito:clientContext?.enabledTemplateWithActito
    });
    refreshCampaignTemplateInformation();
  };

  const handleNewOpenAiSuggest = async (substitutionUpdates: Substitutions) => {
    if (!template) {
      throw new Error("cannot update substitutions for unknown template");
    }

    setIsButtonDisabled(true);

    const { replyTo, subject } = template;
    const fieldName = Object.keys(substitutionUpdates)[0]
    const fieldValue = Object.values(substitutionUpdates)[0]

    const newSubstitutionModelWithOpenAiMode: NewSubstitutionModelWithOpenAi = {
      openAiToken:openAiTokenInput,
      licenseCode:licenseCodeInput,
      templateId: templateId,
      customizations: [
        {field: "replyTo", value: replyTo},
        {field: "subject", value: subject},
        {field: fieldName, value: fieldValue},
      ]
    };

   const {newContent}= await apiPost<{ newContent: string }>(`/api/campaigns/${campaignId}/template/openai`, newSubstitutionModelWithOpenAiMode);
   if (!campaignId) {
      throw new Error("Cannot update status of unknown campaign");
    }
    await updateStatus({
      campaignId,
      user: user || "",
      status: "DRAFT",
      shouldPostEvent: validatePostEvent("customize.event"),
      comment: `${translate("customize.event")}`,
    });
    refreshCampaignTemplateInformation();
    setSubstitutions(substitutions);
    return {...substitutions,  [fieldName]: newContent};
  };

  useEffect(() => {
    if (!template) return;
    if (customizations === undefined) return;
    const fetchedCustomizations = generateCustomizationsObject(customizations);
    const mergedCustomizations = {
      ...defaultTemplateSubstitutions,
      ...fetchedCustomizations,
    };
    setSubstitutions(mergedCustomizations);
    if (channel === "SMS") {
      if (!template.body) return;
      const newSmsText = replaceSmsVariablesWithSubstitutions(template.body, variableNames, mergedCustomizations);
      setSmsText(newSmsText);
      setSmsCounterData(SmsCounter.count(newSmsText));
    }
  }, [channel, customizations, defaultTemplateSubstitutions, template, variableNames]);

  // Targeting related
  const [segments, setSegments] = useState<SegmentStore>();
  const targeting = useTargetingFromCampaign(campaignId);
  const { data: targetingResolution } = useTargetingResolutionFromCampaign(campaignId);
  const count = targetingResolution?.count === undefined ? "unknown" : targetingResolution?.count;
  const enabledTemplateWithActito = clientContext?.enabledTemplateWithActito;
  const {addNotification} = useNotification();
  const [ creatingCampaign, setCreatingCampaign ] = useState(false);

  const handleTemplateSelect = async (newTemplateId: string) => {
    if (!campaignId) {
      let id;
      if(enabledTemplateWithActito && channel === "EMAIL") {
        setCreatingCampaign(true);
        const newCampaign: NewCampaignSpecs = {
          name: campaignName,
          creator: user || "",
          scope: scope || "",
          channel: channel,
          status: "DRAFT",
          templateId: newTemplateId,
          enabledTemplateWithActito:true,
        };
        try{
          const res = await apiPost<{ id: string }>("/api/campaigns", newCampaign);
          id = res.id;
          setCreatingCampaign(false);
        } catch (e) {
          setCreatingCampaign(false);
          setIsSelectionDisabled(false);
          if(e.name === "APIError") {
            addNotification({message: translate("notification.create.error.duplicate"), props: {status: "error"}});
          } else {
            addNotification({message: translate("notification.error"), props: {status: "error"}});
          }
        }
      }
      else{
        const newCampaign: NewCampaignSpecs = {
          name: campaignName,
          creator: user || "",
          scope: scope || "",
          channel: channel,
          status: "DRAFT",
          templateId: newTemplateId,
          enabledTemplateWithActito:false,
        };
        const res  = await apiPost<{ id: string }>("/api/campaigns", newCampaign);
        id = res.id
      }

      if(id) {
        await navigate(`/campaigns/edit-${channel}-campaign/${id}/customize`);
      }
    } else {
      await navigate(`/campaigns/edit-${channel}-campaign/${campaignId}/customize`);
    }
  };

  const stepComponents: Record<WizardStep, JSX.Element> = {
    customize: (
      <Customize
        {...{ setSubstitutions, substitutions, templateId, campaignId, customizations, handleNewSubstitions,handleNewOpenAiSuggest ,isOpenAiActivated,setIsButtonDisabled,isButtonDisabled}}
      />
    ),
    target: <TargetingEditor {...{ campaignId, segments, setSegments, targeting, templateId, subscription, count }} />,
    send: <Send {...{ targeting, count, campaignId, substitutions, templateId }} />,
    template: (
        creatingCampaign ? <Loader/>
            : <TemplateSelector
                currentTemplateId={templateId}
                onTemplateSelect={handleTemplateSelect}
                {...{ campaignName, setCampaignName }}
                isSelectionDisabled={isSelectionDisabled}
                setIsSelectionDisabled={setIsSelectionDisabled}
            />
    ),
  };

  return channel === "SMS" ? (
    <SmsContext.Provider value={{ smsCounterData, setSmsCounterData, smsText, setSmsText }}>
      <Steps {...{ currentStep, templateId, campaignId, props, stepComponents, substitutions, status }} />
    </SmsContext.Provider>
  ) : (
    <Steps {...{ currentStep, templateId, campaignId, props, stepComponents, substitutions, status }} />
  );
};
